import { useTheme } from '@mui/material';
import { GridCellParams, GridColDef, GridFilterInputValue, GridFilterModel, GridValueFormatterParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { DataTable, DataTableProps } from 'components';
import dayjs from 'dayjs';
import { ActivityType, CustomFieldDto, CustomFieldEntityType as CustomFieldEntityTypeEntity, CustomFieldType, EntityType, FindActivitiesQuery, FindThemesWithSpecificationsQuery, SystemFieldId, useFindSectionsQuery, useFindThemesWithSpecificationsQuery } from 'gql';
import orderBy from 'lodash/orderBy';
import { activityTypeMessages } from 'modules/activities/messages';
import { isActivityType } from 'modules/activities/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useCustomFieldsSearchUtils } from '../../../../utils/dataGridSearch/customFieldsSearch';
import { notEmpty } from '../../../../utils/typescriptUtils';
import { ActivityTypeFilterInput } from './components/ActivityTypeFilterInput';
import { ActivityActions } from '../ActivityActions/ActivityActions';
import { isArray } from 'lodash';
import { InterventionWorkerCell } from './components/InterventionWorkerCell';
import { AdditionalWorkersCell } from './components/AdditionalWorkersCell';
import { MultiOptionsCell } from './components/MultiOptionsCell';

type Activity = NonNullable<NonNullable<Pick<FindActivitiesQuery, 'activities'>['activities']>['items']>[0];
type CustomFieldValue = Activity['customFieldValues'][0];

type ActivityTableCellValue = string | Date | CustomFieldValue[] | string[] | undefined;

type CustomFieldEntityType = Pick<CustomFieldEntityTypeEntity, 'entityType' | 'customFieldId'> & {
  customFieldDto: Pick<CustomFieldDto, 'customFieldType' | 'systemFieldId' | 'allowMultipleValues'>;
};

const getFieldName = (p: CustomFieldEntityType) => {
  if (p.entityType === EntityType.Reality)
    return `${p.entityType.toString()};${p.customFieldDto.systemFieldId?.substring(7).toLowerCase()}`;

  return `${p.entityType.toString()};${p.customFieldId.toString()}`;
};

const getCustomValuesByType = (row: Activity, p: CustomFieldEntityType) =>
  (p.entityType === EntityType.Individual ? row.individuals?.flatMap(i => i.customFieldValues) :
    p.entityType === EntityType.UnknownGroup ? row.unknownGroups?.flatMap(i => i.customFieldValues) :
      p.entityType === EntityType.Reality ? row.realities?.flatMap(i => i.values) :
        row.customFieldValues) ?? [];

const shouldReturnMultipleValues = (p: CustomFieldEntityType) =>
  p.customFieldDto.customFieldType === CustomFieldType.Selection || p.customFieldDto.customFieldType === CustomFieldType.Lookup
  || p.entityType === EntityType.Individual || p.entityType === EntityType.UnknownGroup || p.entityType === EntityType.Reality;

const valueGetterByFieldType = (p: CustomFieldEntityType) => ({ row }: GridValueGetterParams<Activity>): ActivityTableCellValue => {
  const customFieldValues = getCustomValuesByType(row, p);

  if (shouldReturnMultipleValues(p)) {
    return customFieldValues.filter(q => q.fieldId === p.customFieldId);
  }

  const fieldType = p.customFieldDto.customFieldType;

  if (fieldType === CustomFieldType.Date) {
    const dateValue = customFieldValues.find(q => q.fieldId === p.customFieldId)?.dateValue;
    return dateValue ? dayjs(dateValue).toDate() : '';
  } else {
    return customFieldValues.find(q => q.fieldId === p.customFieldId)?.displayString;
  }
};

const valueFormatter = ({ value }: GridValueFormatterParams<ActivityTableCellValue>) => {
  if (isArray(value)) {
    if (typeof value[0] === 'string') {
      return value.join(', ');
    } else {
      return (value as CustomFieldValue[]).map(v => v.displayString).filter(v => Boolean(v)).join(', ');
    }
  } else if (value instanceof Date) {
    return dayjs(value).format('YYYY-MM-DD');
  } else {
    return value;
  }
};

const renderCellByFieldType = (p: CustomFieldEntityType) => {
  const systemFieldId = p.customFieldDto.systemFieldId;

  if (systemFieldId === SystemFieldId.OutreachWorker) {
    return InterventionWorkerCell;
  }

  if (systemFieldId === SystemFieldId.AdditionalWorkers) {
    return AdditionalWorkersCell;
  }

  if (shouldReturnMultipleValues(p)) {
    return MultiOptionsCell;
  }

  return undefined;
};

const activitiesTableStorageKey = 'activities-tables';

interface Props extends Omit<DataTableProps, 'columns' | 'rows' | 'noSearchResultsMessage' | 'noDataMessage'> {
  onFilter?: (filter: GridFilterModel) => void,
  onColumnsChange?: (columns: GridColDef[]) => void,
  activities: Activity[];
  onEdit?: (activityId: number) => void;
}
export const ActivitiesTable: React.FC<Props> = ({ onEdit, onColumnsChange, ...props }) => {
  const { columnMinWidth, customFieldTypeToColumnType, getHeaderNamePrefix, getOperatorsByCustomField } = useCustomFieldsSearchUtils();
  const { formatMessage } = useIntl();
  const { activities, ...searchTableProps } = props;
  const theme = useTheme();

  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStateCommunity>();
  const wasGridStateRestored = React.useRef(false);
  const { data: sectionsData, isFetching } = useFindSectionsQuery();
  const sections = useMemo(() => sectionsData?.sections ?? [], [sectionsData?.sections]);
  const { data: realitiesData } = useFindThemesWithSpecificationsQuery();
  const realities = useMemo(() => realitiesData?.themes ?? [], [realitiesData?.themes]);
  const specifications = useMemo(() => realities.flatMap(r => r.specifications), [realities]);

  const getThemeName = (reality: NonNullable<Activity['realities']>[0]) =>
    realities.find(r => r.id === reality.themeId)?.name ?? '';

  const getSpecificationName = (reality: NonNullable<Activity['realities']>[0]) =>
    specifications.find(s => s.id === reality.specificationId)?.name ?? '';

  const columns = useMemo(() => {
    const fieldIds = new Set<number>();
    let interventionDateIdColumn = '';
    const visibilityConfig: Record<string, boolean> = {};
    const newColumns: GridColDef<Activity>[] =
      orderBy(sections.flatMap(p => p.fieldEntityTypes),
        [
          p => p.customFieldDto.systemFieldId === SystemFieldId.OutreachWorker ? 0 : 1,
          p => (isActivityType(p.entityType) ? '0' : p.entityType.toString()),
          p => p.entityType,
          p => p.order
        ])
        .map<GridColDef<Activity> | null>(p => {
          if (fieldIds.has(p.customFieldId)) {
            return null;
          }

          fieldIds.add(p.customFieldId);

          if (p.customFieldDto.systemFieldId === SystemFieldId.InterventionDate) {
            interventionDateIdColumn = `${p.entityType.toString()};${p.customFieldId.toString()}`;
          }

          if (![SystemFieldId.OutreachWorker, SystemFieldId.NickName, SystemFieldId.InterventionDate].includes(p.customFieldDto.systemFieldId as SystemFieldId)) {
            visibilityConfig[`${p.entityType.toString()};${p.customFieldId.toString()}`] = false;
          }

          return {
            field: getFieldName(p),
            sortable: false, // disable client side sorting which results in bad UX when combined with server side pagination
            type: customFieldTypeToColumnType[p.customFieldDto.customFieldType],
            filterOperators: getOperatorsByCustomField(p.customFieldDto),
            hideable: !(p.customFieldDto.systemFieldId === SystemFieldId.OutreachWorker),
            headerName: getHeaderNamePrefix[p.entityType] + p.customFieldDto.name,
            flex: 1,
            minWidth: columnMinWidth[p.customFieldDto.customFieldType],
            valueGetter: valueGetterByFieldType(p),
            renderCell: renderCellByFieldType(p),
            valueFormatter
          };
        }).filter(notEmpty) ?? [];

    const firstRealitiesColumnIndex = newColumns.findIndex(p => p.field?.startsWith(EntityType.Reality.toString()));
    newColumns.splice(firstRealitiesColumnIndex, 0, {
      field: `${EntityType.Reality.toString()};theme`,
      type: 'string',
      sortable: false,
      filterOperators: [{ label: formatMessage({ id: 'Starts with' }), value: 'startsWith', InputComponent: GridFilterInputValue, getApplyFilterFn: () => () => true }],
      headerName: formatMessage({ id: '(Realities) Theme' }),
      minWidth: 200,
      valueGetter: ({ row }) => row.realities?.map(getThemeName).filter(Boolean),
      renderCell: MultiOptionsCell,
      valueFormatter
    });

    newColumns.splice(firstRealitiesColumnIndex + 1, 0, {
      field: `${EntityType.Reality.toString()};specification`,
      type: 'string',
      sortable: false, // disable client side sorting which results in bad UX when combined with server side pagination
      filterOperators: [{ label: formatMessage({ id: 'Starts with' }), value: 'startsWith', InputComponent: GridFilterInputValue, getApplyFilterFn: () => () => true }],
      headerName: formatMessage({ id: '(Realities) Specification' }),
      minWidth: 200,
      valueGetter: ({ row }) => row.realities?.map(getSpecificationName).filter(Boolean),
      renderCell: MultiOptionsCell,
      valueFormatter
    });

    visibilityConfig[`${EntityType.Reality.toString()};theme`] = false;
    visibilityConfig[`${EntityType.Reality.toString()};specification`] = false;

    newColumns.push({
      field: 'Activity;type',
      headerName: formatMessage({ id: 'Activity type' }),
      sortable: false, // disable client side sorting which results in bad UX when combined with server side pagination
      filterOperators: [{ label: formatMessage({ id: 'Equals' }), value: 'eq', InputComponent: ActivityTypeFilterInput, getApplyFilterFn: () => () => true }],
      align: 'left',
      minWidth: 150,
      valueGetter: ({ row }) => { return row.type; },
      valueFormatter: ({ value }) => formatMessage(activityTypeMessages[value as ActivityType]),
    });

    visibilityConfig['Activity;type'] = false;

    newColumns.push({
      field: 'actions',
      headerName: '',
      sortable: false,
      filterable: false,
      hideable: false,
      disableExport: true,
      disableColumnMenu: true,
      align: 'right',
      renderCell: ({ row: activity }) => (
        <ActivityActions
          activity={activity}
          onEdit={onEdit ? () => onEdit(activity.id) : undefined}
          hideDelete
        />
      )
    });

    setInitialState({
      sorting: {
        sortModel: [{ field: interventionDateIdColumn, sort: 'desc' }]
      },
      columns: {
        columnVisibilityModel: visibilityConfig
      }
    });

    return newColumns;
  }, [columnMinWidth, customFieldTypeToColumnType, formatMessage, getHeaderNamePrefix, getOperatorsByCustomField, onEdit, realities, sections, specifications]);

  useEffect(() => {
    if (columns.length > 0) {
      onColumnsChange?.(columns);
    }
  }, [columns.length]);

  const handleOnCellClick = (params: GridCellParams<Activity>) => {
    if (params.field === 'actions') return;
    navigate(`${params.row.id}`);
  };

  const saveActivitiesTableState = useCallback(() => {
    const state = apiRef.current.exportState();
    localStorage.setItem(activitiesTableStorageKey, JSON.stringify(state));
  }, [apiRef]);

  useEffect(() => {
    if (!isFetching && !wasGridStateRestored.current) {
      const stateJSON = localStorage.getItem(activitiesTableStorageKey);

      if (stateJSON) {
        const state: GridInitialStateCommunity = JSON.parse(stateJSON ?? '{}');
        if (state.columns) state.columns.orderedFields = undefined;
        apiRef.current.restoreState({ ...state, preferencePanel: { open: false } });
      } else {
        apiRef.current.restoreState(initialState ?? {});
      }

      wasGridStateRestored.current = true;
    }
  }, [apiRef, initialState, isFetching]);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    props.onFilter?.(filterModel);
    saveActivitiesTableState();
  }, [props, saveActivitiesTableState]);

  return (
    <DataTable
      {...searchTableProps}
      apiRef={apiRef}
      columns={columns}
      rows={activities}
      loading={searchTableProps.loading && activities.length === 0}
      isFetching={props.loading && isFetching}
      filterMode='server'
      onCellClick={handleOnCellClick}
      noDataMessage={formatMessage({ id: 'There are no activities yet' })}
      noSearchResultsMessage={formatMessage({ id: 'No activity found' })}
      sx={{
        '& .MuiDataGrid-cell:first-of-type': {
          padding: 0,
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-virtualScroller': {
          [theme.breakpoints.up('md')]: {
            maxHeight: '72vh',
            overflowY: 'auto !important',
          }
        }
      }}
      onColumnVisibilityModelChange={(a, b) => {
        saveActivitiesTableState();
        props.onColumnVisibilityModelChange?.(a, b);
      }}
      onFilterModelChange={onFilterChange}
      onSortModelChange={saveActivitiesTableState}
    />
  );
};