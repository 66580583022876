import { Merge, Person } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import debounce from '@mui/utils/debounce';
import { GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { NewButton, PageTitle } from 'components';
import { EditIndividualInput, EntityType, IndividualFilterInput, useAddIndividualMutation, useEditIndividualMutation, useFindCustomFieldsQuery } from 'gql';
import { useFindIndividualsCancellableQuery } from 'gql/cancellableQueries';
import { useResponsive } from 'hooks';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';
import { useNotification } from 'hooks/useNotification';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { getFormValueFromFieldValue } from 'utils/customFieldsUtils';
import { useCustomFieldsSearchUtils } from '../../../utils/dataGridSearch/customFieldsSearch';
import { IndividualDialog } from '../components/IndividualDialog';
import { IndividualsMergeDialog } from '../components/IndividualsMergeDialog/IndividualsMergeDialog';
import { IndividualsTable } from '../components/IndividualsTable';

const searchParamKeys = {
  addIndividual: 'addIndividual',
  editIndividualId: 'editIndividualId'
};

export const IndividualsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const queryClient = useQueryClient();
  const notifications = useNotification();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [individualsFilter, setIndividualsFilter] = useState<IndividualFilterInput>({});

  const { isAdministrator } = useCurrentUserRoles();
  const [searchTerm, setSearchTerm] = useState('');
  const { data: individualsData, isFetching: isFetchingIndividuals } = useFindIndividualsCancellableQuery({ pageSize: pageSize, skip: page * pageSize, input: individualsFilter, searchTerm: searchTerm?.trim() ?? '' });
  const { mapGridFilterToCustomValuesFilter } = useCustomFieldsSearchUtils();
  const [gridFilter, setGridFilter] = useState<GridFilterModel>();

  const [searchParams, setSearchParams] = useSearchParams();
  const addIndividual = searchParams.get(searchParamKeys.addIndividual) === 'true';
  const editIndividualId = Number(searchParams.get(searchParamKeys.editIndividualId)) || undefined;

  const isDialogOpen = addIndividual || Boolean(editIndividualId);

  const onEdit = (id: number) => {
    searchParams.set(searchParamKeys.editIndividualId, id.toString());
    setSearchParams(searchParams);
  };

  const onAdd = () => {
    searchParams.set(searchParamKeys.addIndividual, 'true');
    setSearchParams(searchParams);
  };


  const { data: customFields } = useFindCustomFieldsQuery();

  const individualInputToEdit: EditIndividualInput | undefined = useMemo(() => {
    if (!editIndividualId) return undefined;
    const individual = individualsData?.searchIndividuals?.items?.find(i => i.id === editIndividualId);
    return individual ? {
      id: individual?.id,
      values: individual?.customFieldValues.map(getFormValueFromFieldValue) ?? []
    } : undefined;
  }, [editIndividualId, individualsData]);

  const handleDialogClose = () => {
    searchParams.delete(searchParamKeys.addIndividual);
    searchParams.delete(searchParamKeys.editIndividualId);
    setSearchParams(searchParams);
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(['findIndividuals']);
    if (editIndividualId) {
      notifications.notifySuccess(formatMessage({ id: 'Individual updated successfully' }));
    } else {
      notifications.notifySuccess(formatMessage({ id: 'Individual added successfully' }));
    }
    handleDialogClose();
  };

  useEffect(() => {
    let newFilter: IndividualFilterInput = { and: [] };

    if (gridFilter && gridFilter.items.length > 0 && gridFilter.items[0].value != null) {
      const individualsFilters = gridFilter.items.filter(p => p.field.split(';')[0] == EntityType.Individual.toString());

      const filters = [newFilter];

      if (individualsFilters.length > 0) {
        filters.push({ customFieldValues: mapGridFilterToCustomValuesFilter(individualsFilters, customFields?.customFields ?? []) });
      }

      newFilter = {
        and: filters
      };
    }

    setIndividualsFilter(newFilter);

  }, [customFields, gridFilter, mapGridFilterToCustomValuesFilter, searchTerm]);

  const addIndividualMutation = useAddIndividualMutation({ onSuccess });

  const editIndividualMutation = useEditIndividualMutation({ onSuccess });

  const mutationsLoading = addIndividualMutation.isLoading || editIndividualMutation.isLoading;

  const onAddIndividualSubmit = (individual: EditIndividualInput) => {
    if (editIndividualId) {
      editIndividualMutation.mutate({
        input: {
          id: editIndividualId,
          values: individual.values
        }
      });
    } else {
      addIndividualMutation.mutate({
        input: {
          values: individual.values
        }
      });
    }
  };

  const handleSearch = useMemo(() => debounce((searchTerm: string) => setSearchTerm(searchTerm), 500), []);

  const handleDataGridFilter = (filter: GridFilterModel) => {
    setGridFilter(filter?.items.length > 0 ? filter : undefined);
  };

  const [selectionModel, _setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const setSelectionModel = (model: GridRowSelectionModel) => {
    if (model.length > 2) return;

    _setSelectionModel(model);
  };

  const [isIndividualsMergeDialogOpen, setIsIndividualsMergeDialogOpen] = useState(false);

  return <Stack mb={{ xs: 9, md: 0 }}>
    <PageTitle icon={<Person />} title={formatMessage({ id: 'Known individuals' })}
      actionButton={<>
        <NewButton onClick={onAdd} />
        {(isAdministrator && !isMobile) && (
          <Button disabled={selectionModel.length < 2} variant='contained' startIcon={<Merge />} onClick={() => setIsIndividualsMergeDialogOpen(true)}>
            {formatMessage({ id: 'Merge' })}
          </Button>
        )}
      </>}
    />

    <IndividualsTable
      height={600}
      storageKey='individuals-table'
      individuals={individualsData?.searchIndividuals?.items ?? []}
      loading={isFetchingIndividuals && !individualsData}
      isFetching={isFetchingIndividuals}
      onEditClicked={individual => onEdit(individual.id)}
      filterMode='server'
      paginationMode='server'
      pagination={true}
      paginationModel={{ page: page, pageSize: pageSize }}
      rowCount={individualsData?.searchIndividuals?.totalCount ?? 0}
      pageSizeOptions={[10, 25, 50, 100]}
      onPaginationModelChange={(model) => { setPage(model.page); setPageSize(model.pageSize); }}
      onFilter={handleDataGridFilter}
      onSearch={handleSearch}
      checkboxSelection={isAdministrator && !isMobile}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={setSelectionModel}
    />

    <IndividualDialog
      loading={mutationsLoading}
      open={isDialogOpen}
      individual={individualInputToEdit}
      disableTeamSelect={Boolean(individualInputToEdit?.id)}
      onCancel={handleDialogClose}
      onSuccess={onAddIndividualSubmit}
    />

    <IndividualsMergeDialog
      open={isIndividualsMergeDialogOpen}
      onClose={() => setIsIndividualsMergeDialogOpen(false)}
      individual1Id={selectionModel.at(0) as number | undefined}
      individual2Id={selectionModel.at(1) as number | undefined}
    />
  </Stack>;
};