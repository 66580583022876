import React, { ReactNode, useEffect, useState } from 'react';
import { Alert, IconButton, Menu } from '@mui/material';
import { EntityType, CustomFieldType, useFindCustomFieldsQuery, useDeleteCustomFieldMutation, useAddCustomPropertyMutation, useUpdateCustomPropertyMutation, CustomFieldStatusType, useGetOptionsByCustomFieldIdQuery, useAddExistingCustomFieldMutation } from 'gql';
import { CustomFieldList } from '../CustomFieldList';
import { useIntl } from 'react-intl';
import { FormDrawer } from 'components';
import { CustomFieldForm, CustomFieldFormValues } from '../CustomFieldForm';
import { CustomizationFormHeader } from './CustomizationFormHeader';
import { MoreVert, RemoveCircle } from '@mui/icons-material';
import { ConfirmButton } from 'components/Buttons';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { LinkCustomFieldForm } from './LinkCustomFieldForm';
import { entityTypePluralMessages } from 'modules/activities/messages';

interface OwnProps {
  customFieldId?: number;
  sectionId?: number;
  entityType: EntityType;
  isOpen: boolean;
  onClose: () => void;
}

export const CustomizationFieldDrawer: React.FC<OwnProps> = (props) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const [customizationType, setCustomizationType] = useState<CustomFieldType | 'linkExistingField' | undefined>(undefined);
  const formMethods = useForm<CustomFieldFormValues>();
  const { handleSubmit, reset } = formMethods;

  const addExistingFieldMutation = useAddExistingCustomFieldMutation({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(['findCustomFields']);
      queryClient.invalidateQueries(['findSections']);
    }
  });

  const { data: customFieldsData } = useFindCustomFieldsQuery({
    filter: { id: { eq: props.customFieldId } }
  }, { enabled: Boolean(props.customFieldId) });
  const customField = props.customFieldId ? customFieldsData?.customFields[0] : undefined;
  const { data: options } = useGetOptionsByCustomFieldIdQuery({ customFieldId: customField?.id ?? -1 });

  const selectTypeField = (type: CustomFieldType | 'linkExistingField') => {
    setCustomizationType(type);
  };

  const onClose = () => {
    setCustomizationType(undefined);
    props.onClose();
  };

  useEffect(() => {
    if (props.customFieldId && customField) {
      setCustomizationType(customField.customFieldType);
    }
  }, [customField, customField?.customFieldType, props.customFieldId]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const canRemoveFromEntity = customField && customField.entityTypes.length > 1;
  const canDelete = Boolean(customField) && !customField?.isSystemField;

  const deleteFieldMutation = useDeleteCustomFieldMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findCustomFields']);
      queryClient.invalidateQueries(['findSections']);
      onClose();
    }
  });

  useEffect(() => {
    setSelectedCustomFieldId(undefined);
    if (customField) {
      reset({
        fieldType: customField?.customFieldType,
        propertyName: customField?.name,
        propertyDescription: customField?.description ?? '',
        isRequired: customField?.isRequired,
        allowMultipleValues: customField?.allowMultipleValues,
        numberOfLines: customField?.numberOfLines ?? 1,
        entityType: customField?.entityTypes?.at(0)?.entityType,
        customOptions: options?.options,
        status: customField?.status
      });
    } else {
      reset({
        fieldType: customizationType === 'linkExistingField' ? CustomFieldType.String : customizationType,
        propertyName: '',
        propertyDescription: '',
        isRequired: false,
        entityType: props.entityType,
        status: CustomFieldStatusType.Active,
        allowMultipleValues: false,
        numberOfLines: 1,
        customOptions: []
      });
    }
  }, [customField, customizationType, options?.options, props.entityType, reset, props.isOpen, customizationType, props.isOpen]);

  const updateCustomField = useUpdateCustomPropertyMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findCustomFields']);
      queryClient.invalidateQueries(['findSections']);
      onClose();
    }
  });

  const addCustomField = useAddCustomPropertyMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findCustomFields']);
      queryClient.invalidateQueries(['findSections']);
      onClose();
    }
  });

  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState<number | undefined>(undefined);

  const handleSave = (customFieldValues: CustomFieldFormValues) => {
    if (customizationType === 'linkExistingField') {
      if (selectedCustomFieldId != null && props.sectionId) {
        addExistingFieldMutation.mutate({ input: { customFieldId: selectedCustomFieldId, entityType: props.entityType, sectionId: props.sectionId } });
      }

      return;
    }

    if (customField) {
      const options = customFieldValues.customOptions?.map((element, index) => {
        return {
          id: element.id,
          order: index,
          name: element.name,
          customFieldId: element.customFieldId
        };
      });
      customField && updateCustomField.mutate({
        input: {
          id: customField.id,
          isRequired: customFieldValues.isRequired,
          propertyDescription: customFieldValues.propertyDescription,
          propertyName: customFieldValues.propertyName,
          status: customFieldValues.status,
          allowMultipleValues: customFieldValues.allowMultipleValues,
          numberOfLines: customFieldValues.numberOfLines,
          customOptions: options
        }
      });
    } else if (props.sectionId) {
      const options = customFieldValues.customOptions?.map((element, index) => {
        return {
          order: index,
          name: element.name,
          customFieldId: element.customFieldId
        };
      });
      addCustomField.mutate({
        input: {
          fieldType: customizationType ?? CustomFieldType.String,
          customOptions: options,
          sectionId: props.sectionId,
          allowMultipleValues: customFieldValues.allowMultipleValues,
          entityType: customFieldValues.entityType,
          isRequired: customFieldValues.isRequired,
          propertyDescription: customFieldValues.propertyDescription,
          numberOfLines: customFieldValues.numberOfLines,
          propertyName: customFieldValues.propertyName,
          status: customFieldValues.status
        }
      });
    }
  };

  const handleDelete = () => {
    if (customField) {
      deleteFieldMutation.mutate({ input: { id: customField.id, entityType: props.entityType } });
      setAnchorEl(null);
    }
  };

  const isDisabled = addCustomField.isLoading || updateCustomField.isLoading || deleteFieldMutation.isLoading;

  const [searchValue, setSearchValue] = useState('');

  if (!props.customFieldId && !props.sectionId && props.isOpen) {
    console.error('CustomizationFieldDrawer: A section id was not provided despite adding a new custom field.');
  }

  return (
    <FormDrawer
      isFormDirty={formMethods.formState.isDirty}
      disablePadding
      header={customizationType ? (
        <CustomizationFormHeader
          editMode={Boolean(customField)}
          customizationType={customizationType}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
        />
      ) : formatMessage({ id: 'Select Field' })}
      open={props.isOpen}
      onClose={onClose}
      onSave={handleSubmit(handleSave)}
      actionButtons={canDelete && (
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
      )}
      disabledSaveButton={!selectedCustomFieldId && (isDisabled || !formMethods.formState.isDirty)}
      saveButtonMessage={customField ? formatMessage({ id: 'Save' }) : formatMessage({ id: 'Add' })}
      showFooter={Boolean(customizationType)}
    >
      {!customizationType && (
        <CustomFieldList onClick={selectTypeField} />
      )}

      {(customizationType && customizationType !== 'linkExistingField') && <>
        <FormProvider {...formMethods}>
          <CustomFieldForm
            fieldType={customizationType}
            customField={customField}
            disabled={isDisabled}
          />
        </FormProvider>
      </>}

      {(customizationType && customizationType === 'linkExistingField') && (
        <LinkCustomFieldForm
          currentEntityType={props.entityType}
          filter={searchValue}
          onSelectedFieldIdChange={setSelectedCustomFieldId}
          selectedCustomFieldId={selectedCustomFieldId}
        />
      )}

      <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <ConfirmButton
          loading={deleteFieldMutation.isLoading || addExistingFieldMutation.isLoading}
          buttonVariant='menuItem'
          confirmTitle={formatMessage({ id: 'Remove field {fieldName}?' }, { fieldName: customField?.name })}
          confirmText={<>
            {canRemoveFromEntity ? (
              <Alert severity='info' sx={{ mb: 2 }}>
                {formatMessage({ id: 'This field is currently associated with the following entities:' })}

                <ul>
                  {customField.entityTypes.map(e => e.entityType).map(e => (
                    <li key={e} style={{ fontWeight: e === props.entityType ? 600 : undefined }}>
                      {formatMessage(entityTypePluralMessages[e])}
                    </li>
                  ))}
                </ul>

                {formatMessage<ReactNode>({ id: 'The field will be removed from <bold>{entityType}</bold> only.' }, {
                  entityType: formatMessage(entityTypePluralMessages[props.entityType]),
                  bold: str => <b>{str}</b>
                })}
              </Alert>
            ) : (
              <Alert severity='error' sx={{ mb: 2 }}>
                {formatMessage({ id: 'This field will be permanently deleted.' })}
              </Alert>
            )}
            {formatMessage({ id: 'Are you sure?' })}
          </>}
          onConfirm={handleDelete}
          onCancel={() => setAnchorEl(null)}
          outerButtonIcon={<RemoveCircle />}
          outerButtonText={formatMessage({ id: 'Remove' })}
        />
      </Menu>
    </FormDrawer>
  );
};